body,
html {
  /* margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  background: #222;
  font-family: "Roboto", sans-serif;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.perspective {
  height: 100%;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-bottom: 10vh;
}
.field {
  position: relative;
  height: 50vw;
  width: 50vw;
  margin: 1vw auto;
  background: #004d00;
  border-radius: 5%;
  border-bottom-left-radius: 10%;
  border-top-right-radius: 70%;
  border: 2vw solid #994d00;
  transform: rotate(-45deg);
  overflow: hidden;
}

.stripes.field {
  background: repeating-linear-gradient(-45deg, #004d00, #004d00 1.5vw, #004500 1.5vw, #004500 3vw);
}

.right-field-line {
  height: 43.6vw;
  width: 1px;
  background: #fff;
  position: absolute;
  top: -2vw;
  left: 4.45vw;
  z-index: 1000;
  opacity: 0.6;
}

.left-field-line {
  width: 47.5vw;
  height: 1px;
  background: #fff;
  position: absolute;
  bottom: 4.35vw;
  left: 4.45vw;
  z-index: 1000;
  opacity: 0.6;
}
.infield {
  position: absolute;
  height: 20vw;
  width: 20vw;
  background-color: #994d00;
  left: 4vw;
  bottom: 4vw;
  border-top-right-radius: 75%;
  z-index: 2;
}
.infield-inner {
  background: #004d00;
  height: 11vw;
  width: 11vw;
  bottom: 1vw;
  left: 1vw;
  position: absolute;
}
.stripes .infield-inner {
  background: repeating-linear-gradient(-45deg, #004d00, #004d00 1vw, #004500 1vw, #004500 2vw);
}

.home {
  width: 5vw;
  height: 5vw;
  border-radius: 50%;
  background: #994d00;
  position: absolute;
  left: -2.5vw;
  bottom: -2.5vw;
}
.plate {
  content: "";
  background: #fff;
  height: 0.6vw;
  width: 0.7vw;
  position: absolute;
  left: calc(50% - 0.45vw);
  top: calc(50% - 0.11vw);
  transform: rotate(45deg);
}

.plate:before {
  content: "";
  background: #fff;
  height: 0.5vw;
  width: 0.5vw;
  position: absolute;
  left: 0.1vw;
  bottom: -0.2vw;
  transform: rotate(45deg);
}

.pitchers-mound {
  width: 3vw;
  height: 3vw;
  border-radius: 50%;
  background: #994d00;
  position: absolute;
  left: calc(50% - 1.5vw);
  bottom: calc(50% - 1.5vw);
}
.pitchers-mound:after {
  width: 1vw;
  height: 0.25vw;
  background: #fff;
  content: "";
  position: absolute;
  transform: rotate(45deg);
  top: calc(50% - 0.1vw);
  left: calc(50% - 0.5vw);
}

.first,
.second,
.third {
  width: 1.75vw;
  height: 1.75vw;
  background: #994d00;
  position: absolute;
  background: #994d00;
  border-top-left-radius: 50%;
  right: -0.5vw;
  bottom: -0.6vw;
}

.first:after,
.second:after,
.third:after {
  background: #fff;
  content: " ";
  width: 0.5vw;
  height: 0.5vw;
  position: absolute;
  bottom: 0;
  right: 0;
}

.second {
  top: -0.5vw;
  transform: rotate(-90deg);
}

.third {
  left: -0.5vw;
  top: -0.5vw;
  transform: rotate(180deg);
}

.circle {
  width: 1.75vw;
  height: 1.75vw;
  margin: 0 auto;
  background: #ed2222;
  border-radius: 100%;
}

.yellow {
  background: yellow;
}

.focus {
  background: #0dbbff;
}

.pitcher {
  position: relative;
  left: -1vw;
  top: 1.75vw;
}

.catcher {
  position: relative;
  left: -0.5vw;
  top: 1.75vw;
}

.first-baseman {
  position: relative;
  left: 2.5vw;
  top: -3vw;
}

.second-baseman {
  position: relative;
  left: -2.5vw;
  top: 5vw;
}

.shortstop {
  position: relative;
  left: 6vw;
  top: -4vw;
}

.third-baseman {
  position: relative;
  left: -1vw;
  top: 1vw;
}

.left-field {
  position: relative;
  left: -12vw;
  top: 15vw;
}

.left-center {
  position: relative;
  left: -1vw;
  top: 15vw;
}

.right-center {
  position: relative;
  left: 8vw;
  top: 23vw;
}

.right-field {
  position: relative;
  left: 9vw;
  top: 34vw;
}

.outs {
  margin: 10px;
  padding: 5px;
  font-weight: bold;
  position: absolute;
  /* bottom: 4.35vw; */
  left: 4.45vw;
  border-radius: 5px;
  background-color: #000;
}

.pulse {
  animation: pulse 1s infinite;
  animation-duration: 2s;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(236, 107, 107, 0.6);
  }
  70% {
    -webkit-box-shadow: 0 0 0 1.75vh rgba(236, 107, 107, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(236, 107, 107, 0);
  }
}
@keyframes pulse {
  0% {
    /*           transform:rotateY(180deg); */
    -moz-box-shadow: 0 0 0 0 rgba(236, 107, 107, 0.6);
    box-shadow: 0 0 0 0 rgba(236, 107, 107, 0.5);
  }
  70% {
    /*                    transform:rotateY(0deg); */

    -moz-box-shadow: 0 0 0 1.75vh rgba(236, 107, 107, 0);
    box-shadow: 0 0 0 1.75vh rgba(236, 107, 107, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(236, 107, 107, 0);
    box-shadow: 0 0 0 0 rgba(236, 107, 107, 0);
  }
}

.panel {
  background-color: #333;
  border-radius: 6px;
  padding: 30px;
  color: #fff;
  text-align: left;
  margin-bottom: 20px;
}

.question {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 20px;
}

.options input {
  margin-bottom: 10px;
}

/* Quiz Styles */
h2 {
  font-size: 24px !important;
  font-weight: bold !important;
  margin-top: 0;
  margin-bottom: 10px;
}

.react-quiz-container {
  border-radius: 6px;
  padding: 30px;
  color: #fff;
  text-align: left;
  overflow: hidden;
}

.react-quiz-container .startQuizWrapper {
  margin-top: 10px;
}

.react-quiz-container .questionWrapperBody {
  max-width: 500px;
  width: 100%;
  /* margin: 0 auto; */
  border-radius: 6px;
  padding: 0px 0px 10px;
  color: #fff;
  text-align: left;
  flex-shrink: 0;
}

.react-quiz-container .btn {
  margin-bottom: 0;
  font-weight: 600;
  text-align: center;
  padding: 11px 12px;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.react-quiz-container .questionWrapper .btn {
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  font-size: 15px;
  display: block;
  white-space: normal;
  text-align: unset;
}

.react-quiz-container .questionWrapper .btn.correct {
  background: green;
  color: white;
}

.react-quiz-container .questionModal .alert {
  padding: 20px;
  margin-bottom: 21px;
  border: 1px solid transparent;
  border-radius: 2px;
  color: #fff;
}

.react-quiz-container .correct {
  background: green;
}

.react-quiz-container .incorrect {
  background: red;
  color: white;
}

.react-quiz-container .questionWrapper img {
  width: 100%;
}

.react-quiz-container .answerBtn {
  width: 100%;
}

.react-quiz-container .startQuizWrapper .startQuizBtn {
  font-size: 15px;
  border-radius: 2px;
  line-height: 1.35135;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border: 1px solid #d9d9d9;
}

.react-quiz-container .result-answer-wrapper {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-radius: 5px;
  background-color: #4d4d4d;
  overflow: hidden;
}

.react-quiz-container .result-answer-wrapper h3 {
  background-color: #333;
  opacity: 0.8;
  color: #fff;
  margin: 0px;
  padding: 10px 20px;
}

.react-quiz-container .result-answer-wrapper p {
  margin: 0;
}

.react-quiz-container .result-answer-wrapper .explaination {
  padding: 20px;
  margin: 0px 20px 20px 20px;
  border: 1px solid #e8e8e8;
}

.react-quiz-container .result-answer-wrapper .tag-container {
  margin: 20px;
}

.react-quiz-container .result-answer {
  padding: 0px 20px;
  margin-bottom: 20px;
}

.react-quiz-container .quiz-synopsis {
  margin: 15px 0px;
}

.react-quiz-container .quiz-result-filter {
  overflow: hidden;
  width: 120px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background-color: #fefefe;
  margin-bottom: 10px;
  border: 1px solid #e8e8e8;
}

.react-quiz-container .quiz-result-filter select {
  background: transparent;
  border: none;
  font-size: 16px;
  padding: 5px;
  width: 100%;
  height: 30px;
  border: 1px solid #ffffff;
}

.react-quiz-container .tag-container {
  margin-bottom: 20px;
}

.react-quiz-container .selection-tag,
.react-quiz-container .number-of-selection {
  padding: 3px 5px;
  border-radius: 5px;
  background-color: #cecece;
  color: #000;
  margin-right: 5px;
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
}

/* .react-quiz-container .number-of-selection {
  background: #673ab7;
  margin-left: 5px;
}

.react-quiz-container .selection-tag.single {
  background: #3f51b5;
}

.react-quiz-container .selection-tag.multiple {
  background: #ff9800;
} */

@media only screen and (min-width: 1120px) {
  .field {
    transform: scale(0.5) rotate(-45deg);
  }

  .perspective {
    height: calc(90vh - 160px);
  }
}
